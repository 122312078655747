/* spectral-regular - latin */
@font-face {
    font-family: 'Spectral';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../fonts/spectral-v13-latin-regular.woff2') format('woff2');
}
/* spectral-regular - latin */
@font-face {
    font-family: 'Spectral';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('../fonts/spectral-v13-latin-500.woff2') format('woff2');
}
/* spectral-700 - latin */
@font-face {
    font-family: 'Spectral';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('../fonts/spectral-v13-latin-700.woff2') format('woff2');
}

/* ibm-plex-sans-regular - latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../fonts/ibm-plex-sans-v14-latin-regular.woff2') format('woff2');
}
/* ibm-plex-sans-500 - latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('../fonts/ibm-plex-sans-v14-latin-500.woff2') format('woff2');
}
/* ibm-plex-sans-600 - latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local(''), url('../fonts/ibm-plex-sans-v14-latin-600.woff2') format('woff2');
}
