.journey {
    &__picture {
        @include extend('ratio ratio-21x9');

        min-height: 100%;
    }

    &__info {
        @include extend('my-sm-4 my-md-6');

        background-color: $white;
        position: relative;
        z-index: 1;
        margin-top: $spacer * -4;
        margin-left: $spacer * 1;
        margin-right: $spacer * 1;
        box-shadow: rem(15) rem(10) rem(30) rem(10) rgba($black, 0.05);

        @include media-breakpoint-up(sm) {
            margin-right: 0;
            margin-top: 0;
            margin-left: rem(-60);
        }

        @include media-breakpoint-up(md) {
            margin-left: rem(-120);
        }
    }
}
