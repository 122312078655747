.partner-info {
    @include extend('my-el');

    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: rem(800);
    margin: 0 auto;
    text-align: center;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        gap: 3rem;
        text-align: start;
    }

    &__picture {
        @include extend('ratio ratio-1x1');

        width: 30%;
        overflow: hidden;
        border-radius: rem(1000);
        align-self: center;
    }

    &__content {
        align-self: center;
    }
}
