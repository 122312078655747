h1 {
    font-size: 1.383rem;
}

h2 {
    font-size: 1.296rem;
}

h3 {
    font-size: 1.215rem;
}

h4 {
    font-size: 1.138rem;
}

h5 {
    font-size: 1.067rem;
}

h6 {
    font-size: 1rem;
}

@include media-breakpoint-up(sm) {
    h1 {
        font-size: 1.802rem;
    }

    h2 {
        font-size: 1.602rem;
    }

    h3 {
        font-size: 1.424rem;
    }

    h4 {
        font-size: 1.266rem;
    }

    h5 {
        font-size: 1.125rem;
    }

    h6 {
        font-size: 1rem;
    }
}

@include media-breakpoint-up(lg) {
    h1 {
        font-size: 2.488rem;
    }

    h2 {
        font-size: 2.074rem;
    }

    h3 {
        font-size: 1.728rem;
    }

    h4 {
        font-size: 1.44rem;
    }

    h5 {
        font-size: 1.2rem;
    }

    h6 {
        font-size: 1rem;
    }
}
