.scroll-indicator {
    width: rem(64);
    height: rem(64);
    border: rem(1.5) solid $white;
    color: $white;
    border-radius: 50%;
    display: block;
    position: relative;
    animation: down 1.5s infinite;
    -webkit-animation: down 1.5s infinite;
    cursor: pointer;
    transition: color 0.15s, background-color 0.15s;

    &:hover,
    &:focus {
        background-color: $white;
        color: $primary;
    }

    svg {
        position: absolute;
        top: calc(50% - #{rem(-2)});
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@keyframes down {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translateY(#{rem(15)});
    }
    40% {
        transform: translate(0);
    }
}

@keyframes down {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translateY(#{rem(15)});
    }
    40% {
        transform: translate(0);
    }
}
