a {
    &.link {
        text-decoration: none;
        z-index: 1;
        position: relative;

        &:before,
        &:after {
            content: '';
            position: absolute;
            display: block;
            right: 0;
            left: 0;
            bottom: rem(-4);
        }

        &:before {
            height: rem(2);
            background-color: $primary;
            transform: scaleX(0);
            transition: transform 0.15s linear;
        }

        &:after {
            height: 100%;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
