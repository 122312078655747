.text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 1.5em;
        margin-bottom: 0.75em;
    }

    > *:first-child {
        margin-top: 0 !important;
    }

    > *:last-child {
        margin-bottom: 0 !important;
    }
}
