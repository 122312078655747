/* ************************************* */
/*         Do-not-change section         */
/* ************************************* */
$enable-rfs: false;
$enable-negative-margins: true;

/* ************************************* */
/*       Project-specific variable       */
/*         overrides from here on        */
/* ************************************* */

$min-contrast-ratio: 3.5;

$spacer: 1rem;
$spacers: (
    /*   0 */ 0: 0,
    /*   4 */ '0_5': $spacer * 0.25,
    /*   8 */ 1: $spacer * 0.5,
    /*  16 */ 2: $spacer * 1,
    /*  24 */ 3: $spacer * 1.5,
    /*  32 */ 4: $spacer * 2,
    /*  40 */ 5: $spacer * 2.5,
    /*  48 */ 6: $spacer * 3,
    /*  72 */ 9: $spacer * 4.5,
    /*  96 */ 12: $spacer * 6,
    /* 192 */ 24: $spacer * 12
);

$sage: #648d81;
$polo: #8cb4cb;

$primary: $sage;
$primary-light: tint-color($primary, 90%);
$secondary: $polo;
$secondary-light: tint-color($secondary, 90%);

$custom-theme-colors: (
    'sage': $sage,
);

$ff-spectral: 'Spectral';
$ff-ibm-plex: 'IBM Plex Sans';

$font-family-sans-serif: $ff-ibm-plex, system-ui, -apple-system, 'Segoe UI', roboto,
    'Helvetica Neue', 'Noto Sans', 'Liberation Sans', arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// scss-docs-start -variables
$headings-margin-bottom: $spacer * 0.5 !default;
$headings-font-family: $ff-spectral;
$headings-font-style: null !default;
$headings-font-weight: 700;
$headings-line-height: 1.2 !default;
$headings-color: $primary;
// scss-docs-end headings-variables

// scss-docs-start aspect-ratios
$aspect-ratios: (
    '1x1': 100%,
    '4x3': 3 / 4 * 100%,
    '16x9': 9 / 16 * 100%,
    '21x9': 9 / 21 * 100%,
    '3x2': 3 / 2 * 100%,
);
// scss-docs-end aspect-ratios

$btn-padding-y: 1rem;
$btn-padding-x: 2rem;
$btn-padding-y-sm: 0.75rem;
$btn-padding-x-sm: 1.5rem;
$btn-font-size: 1rem;
$btn-font-weight: 600;
$btn-border-radius: 50rem;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;
