.logo {
    font-size: rem(16);
    color: $primary;
    font-family: $headings-font-family;
    white-space: no-wrap;
    line-height: 1.1;
    transition: opacity 0.15s;
    font-weight: 500;

    &:hover {
        opacity: 0.75;
    }
}
