.stage {
    @include extend('gap-2');

    color: $white;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    position: relative;

    &__picture,
    &__content {
        grid-column: 1;
    }

    &__picture {
        @include extend('ratio ratio-21x9');

        position: relative;
        grid-row: 1;
        background-color: $primary;
        min-height: calc(100vh - var(--header-height, 0px));

        img {
            mix-blend-mode: soft-light;
        }
    }

    &__content {
        @include extend('p-2 p-md-6 container-fluid');

        grid-row: 1;
        z-index: 1;
        align-self: flex-end;

        .headline {
            @include extend('h1 mb-0 mx-auto');

            max-width: 30ex;
            font-size: rem(24);
            text-align: center;
            color: inherit;

            @include media-breakpoint-up(sm) {
                font-size: 6vw;
            }

            @include media-breakpoint-up(xl) {
                font-size: 4vw;
            }
        }

        .scroll-indicator {
            @include extend('mt-6 mb-4 mx-auto');
        }
    }

    &__scroll {
        position: absolute;
        bottom: var(--header-height);
        width: 100%;
    }
}
