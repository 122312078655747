header {
    position: sticky;
    top: 0;
    z-index: 10;

    .navbar {
        @include extend('py-lg-3');

        background-color: $white;
        box-shadow: 0 0 rem(20) rgba($black, 0.1);

        .navbar-nav {
            @include extend('gap-lg-3 gap-xl-5');

            align-items: flex-end;
        }

        .nav-link {
            position: relative;
            font-weight: 500;
            color: $gray-600;

            @include media-breakpoint-up(lg) {
                padding-right: 0 !important;
                padding-left: 0 !important;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                bottom: rem(6);
                width: 100%;
                right: 0;
                height: rem(3);
                background-color: currentcolor;
                transform: scaleX(0);
                transition: transform 0.2s, opacity 0.2s;
                opacity: 0;
                border-radius: rem(100);
                transform-origin: right;

                @include media-breakpoint-up(lg) {
                    transform-origin: center;
                    right: calc(50% - #{rem(24)});
                    width: rem(48);
                    bottom: 0;
                }
            }

            &.active,
            &:hover {
                color: $primary;
            }

            &.active,
            &:hover,
            &:focus {
                &:before {
                    transform: scaleX(1);
                    opacity: 1;
                }
            }
        }
    }
}
