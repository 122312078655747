$iconSizes: 8, 16, 24, 32, 40, 48, 56, 64, 80, 120;
$iconRotations: 45, 90, 135, 180, 225, 270, 315;

svg {
    &[data-size] {
        flex-shrink: 0;
    }

    @each $iconSize in $iconSizes {
        &[data-size='#{$iconSize}'] {
            width: rem($iconSize);
            height: rem($iconSize);
        }
    }

    @each $iconRotation in $iconRotations {
        &[data-rotate='#{$iconRotation}'] {
            transform: rotate(#{$iconRotation}deg);
        }
    }
}

.btn {
    display: inline-flex;
    align-items: center;

    &-block {
        width: 100%;
        justify-content: center;
    }

    > svg:first-child:not(:last-child) {
        margin-right: rem(8);

        &[data-size='16'] {
            margin-right: rem(4);
        }
    }

    > svg:last-child:not(:first-child) {
        margin-left: rem(8);

        &[data-size='16'] {
            margin-left: rem(4);
        }
    }
}

svg use {
    fill: currentcolor;
}
