ul {
    &.list-fancy {
        margin-left: $spacer * 2;
        margin-bottom: $spacer * 4;
        list-style: none;
        padding-left: 0;

        @include media-breakpoint-up(lg) {
            .text & {
                font-size: 1.2em;
            }
        }

        li {
            @include extend('my-0_5 ps-3 my-lg-1 ps-lg-4');

            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0.55em;
                height: 0.4em;
                width: 0.4em;
                background-color: $polo;
                border-radius: 0.1em;
                transform: rotate(45deg);
            }
        }
    }
}
