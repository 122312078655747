.menu-button {
    display: grid;
    color: $primary;
    background-color: transparent;
    border: none;

    &__menu,
    &__close {
        grid-row: 1;
        grid-column: 1;
        transition: transform 0.2s;
    }

    &__menu {
        transform: scale(0);
    }

    &__close {
        transform: scale(1);
    }

    &.collapsed {
        .menu-button__menu {
            transform: scale(1);
        }
        .menu-button__close {
            transform: scale(0);
        }
    }
}
