.btn {
    gap: rem(8);

    &-arrow {
        svg {
            transform: translateX(0);
            transition: transform 0.15s;
        }
        &:hover {
            svg {
                transform: translateX(#{rem(4)});
            }
        }
    }
}
